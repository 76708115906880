<template>
  <sgv-table
    :items="itemMapped"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.accountCode">
          {{ item.accountCode }}
        </td>
        <td v-if="hidden.accountName">
          {{ item.accountName }}
        </td>
        <td v-if="hidden.debit">
          <span class="text-success" v-if="item.debit > 0">
            {{ item.debit | comma }}
          </span>
          <span v-else>-</span>
        </td>
        <td v-if="hidden.credit">
          <span class="text-danger" v-if="item.credit < 0">
            {{ item.credit * -1 | comma }}
          </span>
          <span v-else>-</span>
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailOrder`,
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['accountCode', 'accountName', 'debit', 'credit'],
        column: null,
        search: null,
        toolbar: null,
      }
    }
  },
  computed: {
    headers () {
      return [
        {text: 'รหัส', value: 'accountCode'},
        {text: 'ชื่อ', value: 'accountName'},
        {text: 'เดบิด Dr.', value: 'debit'},
        {text: 'เครดิต Cr.', value: 'credit'},
      ]
    },
    itemMapped () {
      return this.items.map(v => {
        return {
          id: v.id,
          accountCode: v.account.code,
          accountName: v.account.name,
          debit: v.amount > 0 ? v.amount : 0,
          credit: v.amount < 0 ? v.amount : 0,
        }
      })
    }
  }
}
</script>

<style lang="css">
</style>
