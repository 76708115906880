<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-date
        v-if="method === 'add'" 
        class="col-6 col-md-4"
        label="วันที่"
        :value="closedDate"
        @input="emitClosedDate"
        :validations="[
          {text: 'required!', value: v.closedDate.$dirty && v.closedDate.$error}
        ]">
      </sgv-input-date>
    </div>

    <div class="form-row" v-if="method !== 'add'">
      <sgv-input-text
        disabled
        class="col-12"
        label="ผู้ติดต่อ"
        :value="getContact">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-12"
        label="รายละเอียด"
        :value="formData.name">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
    closedDate: {
      type: String,
      required: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    getContact () {
      if (!this.formData.contact) return ''
      const {code, name, branch} = this.formData.contact
      const branchTxt = branch && branch !== 'สำนักงานใหญ่' ? `[${branch}]` : ''
      return `${code} (${name}) ${branchTxt}`
    },
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    emitClosedDate (value) {
      this.$emit('update:closedDate', value)
    }
  }
}
</script>

<style lang="css">
</style>
